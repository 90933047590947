import { template as template_38d5db97114d4d8083a3357b4f3149a2 } from "@ember/template-compiler";
const FKLabel = template_38d5db97114d4d8083a3357b4f3149a2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
