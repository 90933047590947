import { template as template_ab6456890659496b92ad1a2089551fe2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ab6456890659496b92ad1a2089551fe2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
