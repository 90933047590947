import { template as template_5cc5b49e4fce498d9c830f6f87eb5e0f } from "@ember/template-compiler";
const FKControlMenuContainer = template_5cc5b49e4fce498d9c830f6f87eb5e0f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
